<template>
<div>
<div class="part-ten" style="background-color: #3f3f3f;">
	<!-- 顶部开始 -->
      <div class="part-ten-top-content" >
        <a class="part-ten-top-content-item" href="https://www.yunzmall.com/plugins/shop_server/notice?class_id=0&i=10&mid=277" style="">
          <img src="https://yunzmall.obs.cn-south-1.myhuaweicloud.com/images/10/2021/09/d8eaa1d858e33b9665bfd7fffe24a476.png" alt="" style="width:60px;height:60px">
          <div class="text">
            <div class="h3">专注于智慧商业</div>
            <div class="h4">懂电商运营，更懂智慧商业系统</div>
          </div>
        </a>
        <a class="part-ten-top-content-item" href="https://www.yunzmall.com/plugins/shop_server/knowledge/knowledge_detail/21?base_id=17&i=10&mid=62000">
          <img src="https://yunzmall.obs.cn-south-1.myhuaweicloud.com/images/10/2021/09/cb99dbd8bffb049eacac2cd5ca91a65f.png" alt="" style="width:60px;height:60px">
          <div class="text">
            <div class="h3">0元下载体验</div>
            <div class="h4">支持免费下载到本地安装部署</div>
          </div>
        </a>
        <a class="part-ten-top-content-item" href="https://www.yunzmall.com/plugins/shop_server/articleContent/44?i=10">
          <img src="https://yunzmall.obs.cn-south-1.myhuaweicloud.com/images/10/2021/09/f5212f2f8362839fe2e66bf7f932858e.png" alt="" style="width:60px;height:60px">
          <div class="text">
            <div class="h3">1对1技术支持</div>
            <div class="h4">专属客户经理提供技术支持</div>
          </div>
        </a>
        <a class="part-ten-top-content-item" href="https://www.yunzmall.com/plugins/shop_server/knowledge/knowledge_detail/526?base_id=12&i=10&mid=62000">
          <img src="https://yunzmall.obs.cn-south-1.myhuaweicloud.com/images/10/2021/09/5746a2dc068009c6f15838e911ef67dd.png" alt="" style="width:60px;height:60px">
          <div class="text">
            <div class="h3">交易合同发票</div>
            <div class="h4">为企业合作保驾护航</div>
          </div>
        </a>
      </div>
    </div>
    <!-- 顶部结束 -->
    <div class="main" style="background-color: #4d4d4d;">
      <div class="w-1200">
        <div class="main-content" style="padding:58px 0px 58px 0">
          <div class="main-left">
            <div class="list">
              <div class="title">产品服务</div>
               <div class="main-left-line"></div>
              <div class="ul">
                <a class="li" href="https://drive.weixin.qq.com/s?k=AKwA5gdpAA0A7Ji11i">产品介绍PPT</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/articleContent/44?i=10">免费部署</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/catelist/613?i=10">视频教程</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/register/agreement?enable=0&i=10">用户协议</a>
              </div>
            </div>
            <div class="list">
              <div class="title">资源中心</div>
              <div class="main-left-line"></div>
              <div class="ul">
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/microHome/microIndex?cat_id=newClassification&i=10">商家社区</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/microHome/microIndex?cat_id=26&i=10">开发</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/microcommentdetails/185?i=10">VUE开源代码</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/microcommentdetails/186?i=10">数据字典</a>
              </div>
            </div>
            <div class="list">
              <div class="title">解决方案</div>
              <div class="main-left-line"></div>
              <div class="ul">
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/goods/7442?i=10">智慧店铺解决方案</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/goods/8195?i=10">知识付费解决方案</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/goods/6799?i=10">电子合同私有化部署</a>
                <a class="li" href="https://www.yunzmall.com/plugins/shop_server/goods/7373?i=10">供应链中台系统</a>
              </div>
            </div>
            <div class="list">
              <div class="title">联系我们</div>
              <div class="main-left-line"></div>
              <div class="ul">
                <a class="li" href="javascript:void(0)">电话：15216771448</a>
                <a class="li" href="javascript:void(0)">邮箱：weiping@yunzmall.com</a>
                <a class="li" href="javascript:void(0)">地址：广州市白云区机场路1600号汇创意产业园C2栋2楼</a>
              </div>
            </div>
          </div>
          <div  style="background: #fff;height: 250px;width: 2px;margin:0 50px;"><!-- 线条 --></div>
          <div class="main-right">
            <!-- 右侧图片 -->
            <div class="img-item">
              <img src="https://yunzmall.obs.cn-south-1.myhuaweicloud.com/images/0/2021/09/n47q79CeYzpp9YbfoyN97F9qqbFby9.jpg" alt="" style="width:130px;height:130px">
              <div class="text">合作咨询</div>
            </div>
            <div class="img-item" style=" margin-left: 50px;">
              <img src="https://yunzmall.obs.cn-south-1.myhuaweicloud.com/newimage/7c00e3dfbf2b977e1016e621809e98d0.jpg" alt="" style="width:130px;height:130px">
              <div class="text">关注公众号了解更多</div>
            </div>
          </div>
        </div>
	<!-- 底部 -->
        <div class="main-middle-line" style="padding:0 0 30px 0">
          <div style="height: 1px;background-color: #ffffff;"></div>
        </div>
        <div class="main-bottom">
          <a href="https://www.yunzmall.com" class="main-bottom-text">广州市芸众信息科技有限公司</a>
          <a href="https://beian.miit.gov.cn/" class="main-bottom-text" style="margin-left: 15px;">备案号：粤ICP备17018310号-3</a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011102002664" class="main-bottom-text" style="margin-left: 15px;">粤公网安备 44011102002664号</a>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  methods: {},

  components: {},
};

</script>
<style  scoped>
   .part-ten-top-content{
     display: flex;
     background-color: rgb(63, 63, 63);
    padding: 30px 0 30px 0;
    justify-content: space-evenly;    
   }
   .part-ten-top-content-item{
    display: flex;
    align-items: center;
   }
   .text{
    text-align: center;
    color: #ffffff;
    margin-left: 5px;
   }
   .h3{
    margin: 0 0 16px;
    padding: 0;
    line-height: 22px;
    font-size: 22px;
   }
   .h4{
    line-height: 16px;
    font-size: 14px;
   }
   a{
    text-decoration: none;
   }
   .main-left{
    display: flex;
    color: #ffffff;
   }
   .main-left-line{
    width: 80px;
    height: 4px;
    background-color: #ffffff;
    margin-top: 10px;
   }
   .ul{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 14px;
   }
   .ul .li{
    color: #ffffff;
    margin-top: 20px;
   }
   .list{
    margin-left: 50px;
   }
   .title{
    text-align: left;
    font-size: 20px;
   }
   .main .w-1200 .main-content{
    display: flex;
    justify-content: center;   
   }
   .main .w-1200 .main-content .main-right{
    display: flex;
    align-items: center;
   }
   .img-item .text{
    margin-top: 20px;
   }
   .main-bottom{
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
   }
   .main-bottom-text{
    color: #ffffff;
   }
</style>