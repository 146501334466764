<!--  -->
<template>
  <el-dialog
    :visible.sync="show"
    center
    :show-close="true"
    :before-close="handleClose"
    width="65%">
    <div class="dialog-content">
      <div class="dialog-content-left">
        <img src="../../assets/dialog-img1.png" alt="">
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 8px;">
            <div style="width: 353px;height: 45px;background: linear-gradient(90deg, rgba(6,216,251,0) 0%, #06D8FB 43%, #06D8FB 51%, rgba(6,216,251,0) 100%);text-align: center;line-height: 45px;color: #fff;font-size:18px">
                7年专注智慧商业
            </div>
            <div style="font-size: 38px;font-weight:bold;color:#ffffff;margin-top:20px">
              全面助力企业数字化转型成功
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px;">
                <div style="width: 64px;height: 4px;background: linear-gradient(90deg, rgba(6,216,251,0) 0%, #06D8FB 100%);margin-top: 4px;margin-right: 4px;"></div>
                <div style="font-size: 16px;color:#ffffff;letter-spacing: 2px;">全方位系统功能·全链路运营服务</div>
                <div style="width: 64px;height: 4px;background: linear-gradient(90deg, #06D8FB 0%, rgba(6,216,251,0) 100%);margin-top: 4px;margin-right: 4px;"></div>
            </div>
            <img src="../../assets/dialog-img2.png" alt="" style="margin-top:30px">
        </div>
      </div>
      <div class="dialog-content-right">
          <div style="display: flex;align-items: center;width: 100%;">
              <img src="../../assets/dialog-img3.png" alt="">
              <div style="margin-left:15px">
                <div style="color:#333333;font-size:24px;font-weight: bold;">我是您的专属私域顾问</div>
                <div style="color: rgba(51,51,51,0.7);font-size:18px;letter-spacing: 4px;margin-top:4px">扫码加微信 领取专属红包</div>
              </div>
          </div>
          <div style="font-size: 24px;font-weight: bold;color: #2E5EFA;margin-top:34px">
            扫码免费领取《十大标杆电商品牌案例介绍》
          </div>            
          <img src="../../assets/dialog-img-code.png" alt="" style="margin-top:40px">
          <div class="main-conte-right-button">
              <div style="letter-spacing: 4px;">扫码添加</div>
              <div style="    width: 1px;background-color: #ffffff;height: 20px;"></div>
              <div style="letter-spacing: 4px;">免费领取</div>
          </div>
          <div style="margin-top:20px;display:flex;">
            <svg t="1692867019418" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3988" width="20" height="20"><path d="M109.696 267.264q0-53.248 29.696-106.496 31.744-58.368 60.416-69.632 14.336-6.144 38.912-12.288t39.936-6.144q8.192 0 12.288 2.048 10.24 3.072 30.72 44.032 6.144 10.24 16.384 30.72t20.48 35.84 17.408 30.72q2.048 2.048 10.24 14.336t12.288 20.48 4.096 16.384q0 11.264-16.384 27.648t-35.84 31.744-34.816 30.72-16.384 25.6q0 5.12 3.072 13.312t4.096 11.264 8.192 14.336 7.168 10.24q43.008 78.848 99.328 135.168t134.144 99.328q1.024 0 10.24 6.144t14.336 8.192 11.264 5.12 13.312 2.048q10.24 0 25.6-15.36t30.72-35.84 31.744-35.84 28.672-16.384q7.168 0 15.36 4.096t20.48 12.288 14.336 10.24q14.336 8.192 30.72 17.408t36.864 20.48 30.72 17.408q39.936 19.456 43.008 29.696 2.048 4.096 2.048 12.288 0 15.36-6.144 39.936t-12.288 38.912q-11.264 28.672-69.632 61.44-53.248 28.672-105.472 28.672-16.384 0-30.72-2.048t-32.768-7.168-26.624-8.192-31.744-11.264-28.672-10.24q-55.296-20.48-99.328-48.128-73.728-45.056-151.552-122.88t-122.88-151.552q-27.648-44.032-47.104-99.328-2.048-5.12-10.24-28.672t-12.288-31.744-8.192-26.624-7.168-32.768-2.048-29.696z" fill="#333333" p-id="3989"></path></svg>
            <div style="letter-spacing: 4px;margin-left:4px">热线：15216771448</div>          
          </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
    </span>
  </el-dialog>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
name:'',
props:{
  show:Boolean
},
model: {
    prop: 'show',		// 默认值为value
    event: 'update:show'		// 默认值为input
},
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
return {
  
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  handleClose(done) {
    this.$emit('update:show',false);
  }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
/* @import url(); 引入公共css类 */
.dialog-content{
  display: flex;
}
.dialog-content-left{
  width: 50%;
  /* height: 610px; */
  background: linear-gradient(138deg, #203DF5 0%, #3E85FF 100%);
  border-radius: 10px 0px 0px 10px;
  padding: 30px;
}
.dialog-content-right{
  width: 45%;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-conte-right-button{
  width: 186px;
  height: 37px;
  background: #2B57F9;
  border-radius: 23px;
  margin-top:30px;
  padding:9px 20px;
  color:#ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
}
</style>