import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta';
import Http from '../src/utils/http';

import 'element-ui/lib/theme-chalk/index.css';
import { Tabs,TabPane,Dialog,Input,Form,FormItem,Button,Loading ,Card,Message} from "element-ui";
window.$http = Http;
Vue.use(Tabs);
Vue.use(TabPane)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Loading);
Vue.use(Card);
// Vue.use(MessageBox);
// Vue.use(Message);

// Vue.use($http);
Vue.config.productionTip = false
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.use(Meta);

Vue.prototype.$navigate = function(method, path, query = {}, params = {}) {
  const routeOptions = { path };
  if (query) {
    routeOptions.query = query;
  }

  if (params) {
    routeOptions.params = params;
  }

   // 检查当前路由是否与目标路由相同
   
   
   const currentRoute = router.currentRoute;
   const isSameRoute = currentRoute.name === path &&
    JSON.stringify(currentRoute.query) === JSON.stringify(query) &&
    JSON.stringify(currentRoute.params) === JSON.stringify(params);
    console.log(isSameRoute);
   if (!isSameRoute) {
     switch (method) {
       case 'push':
         router.push(routeOptions);
         break;
       case 'replace':
         router.replace(routeOptions);
         break;
       case 'go':
         router.go(path);
         break;
       default:
         throw new Error(`Unknown navigation method: ${method}`);
     }
   } else {
     console.warn('Already on the same route. Navigation aborted.');
   }
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
