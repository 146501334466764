import axios from 'axios';
import Fun from "./index";
import store from '../store/index'; // 引入Vuex store
// 创建axios实例
let instance = axios.create({
  //https://www.yunzmall.com
  baseURL: ``, // 基础URL
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': '' // 示例授权头
  }
});
// instance.baseURL += `/addons/yun_shop/api.php?i=${Fun.getKeyByI()}&type=10&route=`;
// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    config.url = config.url.replace(/\/+$/, '');
    //${Fun.getKeyByI()}
    let baseURL = `/addons/yun_shop/api.php?i=10&type=5&route=`
    config.url = baseURL + config.url
    console.log(config.url);
    store.dispatch('startLoading'); // 开始加载
    return config;
  },
  error => {
    // 对请求错误做些什么
    store.dispatch('stopLoading'); // 结束加载
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    store.dispatch('stopLoading'); // 结束加载
    console.log(response);
    return response;
  },
  error => {
    store.dispatch('stopLoading'); // 结束加载
    // 对响应错误做点什么
    if (error.response) {
      // 请求已发出但服务器响应的状态码不在2xx范围内
      console.error('Response Error:', error.response.data);
      console.error('Status Code:', error.response.status);
      console.error('Headers:', error.response.headers);
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      console.error('Request Error:', error.request);
    } else {
      // 一些设置请求时发生错误
      console.error('Error Message:', error.message);
    }
    return Promise.reject(error);
  }
);

// 封装一个Promise接口模板
function httpRequest(url, method = 'GET', data = {}, headers = {}) {
  console.log('urlurlurl',url)
  return new Promise((resolve, reject) => {
    instance({
      url,
      method,
      [method.toLowerCase() === 'get' ? 'params' : 'data']: data,
      headers: { ...instance.defaults.headers, ...headers } // 合并默认头和自定义头
    })
    .then(response => {
      console.log('222222222',url);
      resolve(response.data);
    })
    .catch(error => {
      console.log('222222222',url);
      reject(error);
    });
  });
}
export default {
  httpRequest
}