import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import("../views/index/index.vue"),
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import("../views/index/index.vue"),
    // redirect:'/index'
  },
  {
    path: '/product',
    name: 'product',
    component: () => import("../views/product/product.vue"),
    meta: {
      title: "产品服务",
      foot: true
    }
    // redirect:'/product'
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import("../views/cooperation/cooperation.vue"),
    meta: {
      title: "加盟合作",
      foot: true
    }
    // redirect:'/product'
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
