<template>
  <div id="app">
    <LoadingSpinner />
    <top-company-info></top-company-info>
    <topNav @openDialog="openDialog" ></topNav>
    <!-- 其他全局组件-->
    <router-view></router-view>

    <part-ten></part-ten>
    <service></service>
    <index-dialog v-model:show="dialogVisible"></index-dialog>
  </div>
</template>

<script>


import topCompanyInfo from './components/top_company_info.vue'
import topNav from './components/top_nav.vue'
import partTen from './components/part_ten.vue'
import service from './components/service.vue'
import indexDialog from './components/compPop/indexDialog'
import LoadingSpinner from './components/LoadingSpinner.vue'
export default {
  metaInfo: {
    title: "芸众科技-芸众智慧商城-供应链中台系统-服务商系统广州市芸众信息科技有限公司 ",
    titleTemplate: "芸众科技-芸众智慧商城-供应链中台系统-服务商系统广州市芸众信息科技有限公司",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
     
      dialogVisible:false
    };
  },

  methods: {
    openDialog(){
      this.dialogVisible = true
    },
    isPhone() {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      return true;
    } else {
      return false;
    }
    },
    
  },
  components: {topCompanyInfo,topNav,partTen,service,indexDialog,LoadingSpinner},
  mounted() {
    document.dispatchEvent(new Event("render-event"));
    
  },
  created(){
    if(this.isPhone()){
      window.location.href = 'https://www.yunzmall.com/addons/yun_shop/?menu#/home?i=10'
    }
  }
};
</script>

<style scoped>
@import "assets/css/index.css";
body {
  padding: 0;
  margin: 0;
}



</style>
